<template>
    <div class="admin wrapper is-vertical is-fullpage is-vcentered">
    </div>
    <Navbar class="is-fixed-bottom" />
</template>

<script>

import Navbar from "@/components/Navbar.vue";

export default {
    name: "Admin",
    components: {
        Navbar
    },
    mounted: function() {
        //document.documentElement.classList.add('has-navbar-fixed-bottom');
        window.admin = this;
    }
}
</script>

<style lang="scss" scoped>
    .admin {
        max-height: 100vh;
    }
</style>